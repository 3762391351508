import React from 'react';
import '../footer/Footer.css'

export default function Footer() {

    return (
        <>
        <div className='footer'>
           bogdanreeds@gmail.com 

           <a href="https://www.instagram.com/bogdanreeds"><i class="fab fa-instagram"></i></a>
           <a href="https://www.facebook.com/bogdanreeds"><i class="fab fa-facebook"></i></a>
        </div>
        </>
    );
}

